<template>
    <div>
        <el-scrollbar class="set-container">
            <div class="main-content">
                <div class="content">
                    <div class="form-content">
                        <el-form :model="addForm" label-width="150px" :rules="rules" ref="addForm"
                                 class="demo-ruleForm">
                            <el-form-item label="封面图" prop="cover">
                                <el-upload
                                        class="cover-uploader"
                                        action="string"
                                        :headers="headerParam"
                                        :show-file-list="false"
                                        :before-upload="beforeCoverUpload"
                                        :http-request="UploadImage">
                                    <div class="inner-box">
                                        <div class="inner-content">
                                            <i class="el-icon-plus"></i>
                                            <span class="inner-text">上传封面</span>
                                        </div>
                                    </div>
                                    <div class="cover" v-if="addForm.cover">
                                        <img :src="addForm.cover">
                                        <div class="cover-bottom">重新上传</div>
                                    </div>
                                </el-upload>
                            </el-form-item>
                            <el-form-item label="商品" class="other-item">
                                <el-button type="primary" @click="chooseGoods">选择商品</el-button>
                                <div class="show-goods">
                                    <div class="goods-item" v-for="(item,index) in chosenGoods" :key="index">
                                        <div class="item-cover">
                                            <img :src="item.img_url" alt="">
                                            <div class="item-shadow" v-if="item.show">
                                                <i class="el-icon-check"></i>
                                            </div>
                                        </div>
                                        <div class="item-text">{{item.goods_name}}</div>
                                        <i class="el-icon-close" @click="delGoods(item,index)"></i>
                                    </div>
                                </div>
                            </el-form-item>
                            <el-form-item label="标题" prop="title">
                                <el-input v-model="addForm.title" maxlength="255" show-word-limit></el-input>
                            </el-form-item>
                            <el-form-item label="概要" prop="summary">
                                <el-input type="textarea" :rows="5" v-model="addForm.summary" maxlength="500"
                                          show-word-limit></el-input>
                            </el-form-item>
                            <el-form-item label="详情" prop="detail" label-position="left">
                                <vue-tinymce :setting="setting" v-model="addForm.detail"></vue-tinymce>
                            </el-form-item>
                            <el-form-item>
                                <el-button @click="goBack">返回</el-button>
                                <el-button type="primary" style="margin-left: 30px" @click="submitForm('addForm')">保存
                                </el-button>
                            </el-form-item>
                        </el-form>
                    </div>
                </div>
            </div>
        </el-scrollbar>
        <el-dialog title="选择商品" :visible.sync="dialogVisible" width="40%"
                   custom-class="custom-dialog"
                   :close-on-click-modal="false" @opened="openedDialog" @close="closeDialog">
            <div class="goods-content">
                <div class="top-content">
                    <span class="top-title">商品分类：</span>
                    <el-select v-model="categoryId" placeholder="请选择" @change="changeCategory" clearable>
                        <el-option
                                v-for="item in categoryList"
                                :key="item.category_id"
                                :label="item.category_name"
                                :value="item.category_id">
                        </el-option>
                    </el-select>
                </div>
                <div class="goods-main">
                    <div :class="index > 4?'goods-item bottom-goods':'goods-item'" v-for="(item,index) in goodsList"
                         @click="changeGoods(item)" :key="index">
                        <div class="item-cover">
                            <img :src="item.img_url" alt="">
                            <div class="item-shadow" v-if="item.show">
                                <i class="el-icon-check"></i>
                            </div>
                        </div>
                        <div class="item-text">{{item.goods_name}}</div>
                        <div class="item-text">{{item.price}}</div>
                    </div>
                </div>
                <el-pagination class="pages-center"
                               :current-page="goodsPages.currentPageNum"
                               :page-size="goodsPages.eachPageNum"
                               layout="prev, pager, next, jumper"
                               :total="goodsPages.total"
                               @current-change="pageCurrentChange">
                </el-pagination>
            </div>
            <div slot="footer" style="text-align: center">
                <el-button @click="cancel">取消</el-button>
                <el-button type="primary" @click="confirmGoods" style="margin-left: 30px">确定
                </el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    import {
        talk_addCourierTalk,
        talk_uploadCourierTalkImg,
        goods_goodsList,
        categoryList,
        talk_getDetail
    } from '@/config/apis'

    export default {
        data() {
            let checkCover = (rule, value, callback) => {
                if (this.addForm.cover === '') {
                    callback(new Error('请上传封面！'));
                } else {
                    callback();
                }
            };
            return {
                infoList: [],
                categoryList: [],
                goodsList: [],
                categoryId: '',
                chosenGoods: [],
                goodsPages: {
                    currentPageNum: 1,
                    eachPageNum: 10,
                    total: 0,
                },
                addForm: {
                    cover: '',
                    title: '',
                    summary: '',
                    detail: '',
                    courier_talk_id: null,
                },
                dialogVisible: false,
                rules: {
                    cover: [
                        {required: true, validator: checkCover, trigger: 'change'}
                    ],
                    title: {required: true, message: '请输入标题', trigger: 'blur'},
                    summary: {required: true, message: '请输入概要', trigger: 'blur'},
                    detail: {required: true, message: '请输入详情', trigger: 'blur'},
                },
                headerParam: {
                    Authorization: localStorage.getItem('schoolToken') || ''
                },
                setting: {
                    menubar: false,
                    plugins: "lists, advlist,emoticons,fullscreen,table,insertdatetime,wordcount,image",
                    toolbar: [
                        'undo redo | styleselect | bold italic underline strikethrough|fontselect fontsizeselect formatselect|alignleft aligncenter alignright|bullist numlist|',
                        'image emoticons forecolor backcolor insertdatetime wordcount|table tabledelete|fullscreen|'
                    ],
                    language: 'zh_CN', //本地化设置
                    height: 300,
                },
            }
        },
        mounted() {
            if (this.$route.query.id) {
                this.addForm.courier_talk_id = this.$route.query.id
                this.getEditInfo()
            }
            this.getCategoryList()
        },
        methods: {
            getEditInfo() {
                talk_getDetail({courier_talk_id: this.addForm.courier_talk_id}).then(res => {
                    this.addForm.title = res.data.title
                    this.addForm.detail = res.data.detail
                    this.addForm.summary = res.data.summary
                    this.addForm.cover = res.data.cover_url
                    this.chosenGoods = res.data.goods_list
                }).catch(err => {
                    console.error(err)
                })
            },
            getCategoryList() {
                let params = {
                    paging: '0',
                }
                categoryList(params).then((res) => {
                    this.categoryList = res.data.list
                })
            },
            getGoodsList(id) {
                let params = {
                    paging: '1',
                    pageSize: this.goodsPages.eachPageNum,
                    page: this.goodsPages.currentPageNum,
                }
                if (id) {
                    params.category_id = id
                }
                goods_goodsList(params).then((res) => {
                    this.goodsList = res.data.list.map(item => {
                        item.show = false
                        this.chosenGoods.forEach(subItem => {
                            if (subItem.goods_id == item.goods_id) {
                                item.show = true
                            }
                        })
                        return item
                    })
                    this.goodsPages.total = res.data.total
                    if (this.goodsPages.total !== 0 && this.goodsPages.length === 0) {
                        this.goodsPages.currentPageNum--;
                        this.getGoodsList();
                    }
                })
            },
            // 切换页面
            pageCurrentChange(val) {
                this.goodsPages.currentPageNum = val
                this.getGoodsList()
            },
            beforeCoverUpload(file) {
                // const isSize = new Promise((resolve, reject) => {
                //     const width = 356;
                //     const height = 200;
                //     const _URL = window.URL || window.webkitURL;
                //     const img = new Image();
                //     img.onload = () => {
                //         const valid = img.width === width && img.height === height;
                //         valid ? resolve() : reject();
                //     };
                //     img.src = _URL.createObjectURL(file);
                // }).then(() => {
                //         return file;
                //     }, () => {
                //         this.$message.warning('图片尺寸限制为356px x 200px');
                //         return Promise.reject();
                //     },
                // );
                // return isSize;
            },
            UploadImage(param) {
                let formData = new FormData()
                formData.append('talk_img', param.file)
                talk_uploadCourierTalkImg(formData).then(res => {
                    if (res.code === 200) {
                        this.addForm.cover = res.data.url; //无域名
                        this.$message.success('上传成功')
                    } else {
                        this.$message.error('上传失败，请稍后再试~')
                    }
                }).catch(err => {
                    console.log('图片上传失败')
                    param.onError()
                })
            },
            goBack() {
                this.$router.go(-1)
            },
            chooseGoods() {
                this.getGoodsList()
                this.dialogVisible = true
            },
            openedDialog() {

            },
            closeDialog() {
                this.resetForm()
            },
            resetForm() {
                this.dialogVisible = false;
            },
            changeCategory(val) {
                this.getGoodsList(val)
            },
            cancel() {
                this.categoryId = ''
                this.dialogVisible = false
            },
            confirmGoods() {
                this.dialogVisible = false
            },
            changeGoods(item) {
                item.show = !item.show
                //默认是false，即不显示shadow
                if (item.show) {
                    let obj = {
                        img_url: item.img_url,
                        goods_name: item.goods_name,
                        goods_id: item.goods_id,
                        price: item.price
                    }
                    this.chosenGoods.push(obj)
                } else {
                    this.chosenGoods = this.chosenGoods.filter(subItem => {
                        return subItem.goods_id != item.goods_id
                    })
                }
            },
            delGoods(item, index) {
                this.chosenGoods.splice(index, 1)
            },
            submitForm(formName) {
                if (this.chosenGoods.length == 0) {
                    this.$message.warning('请选择商品！')
                    return
                }
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        let arr = []
                        this.chosenGoods.forEach(item => {
                            arr.push(item.goods_id)
                        })
                        let params = {
                            title: this.addForm.title,
                            cover_url: this.addForm.cover,
                            summary: this.addForm.summary,
                            detail: this.addForm.detail,
                            goods_id_arr: arr
                        }
                        if (this.addForm.courier_talk_id) {
                            params.courier_talk_id = this.addForm.courier_talk_id
                        }
                        talk_addCourierTalk(params).then((res) => {
                            this.$message.success(res.msg)
                            this.dialogVisible = false
                            this.goBack()
                        }).catch((err) => {
                            console.error(err)
                        })
                    } else {
                        return false;
                    }
                });
            }
        }
    }
</script>

<style scoped lang="scss">
    ::v-deep .set-container {
        margin: 20px;
        background: #fff;
        height: calc(100% - 40px);
        border: 1px solid #e6e6e6;
        border-radius: 4px;

        & > .el-scrollbar__wrap {
            overflow-x: hidden;
        }
    }

    .main-content {
        margin: 20px;
        background: #fff;
        height: calc(100% - 40px);
        display: flex;
        flex-direction: column;
    }

    .tab {
        text-align: right;
    }

    .content {
        flex: 1;
        height: 1%;
        padding: 20px;
        display: flex;
        flex-direction: column;
    }

    .form-content {
        flex: 1;
        height: 1%;
        margin-top: 20px;
    }

    .cover-uploader {
        width: 500px;

        ::v-deep.el-upload {
            display: flex;
            align-items: center;

            .inner-box {
                width: 200px;
                height: 113px;
                background: #F7F7F7;
                border-radius: 2px;

                .inner-content {
                    padding: 29px 0;
                    display: flex;
                    flex-direction: column;

                    i {
                        font-size: 24px;
                    }

                    .inner-text {
                        font-size: 16px;
                    }
                }
            }

            .cover {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 200px;
                height: 113px;
                margin-left: 15px;
                position: relative;

                img {
                    width: 100%;
                    height: 100%;
                }

                .cover-bottom {
                    position: absolute;
                    bottom: 0;
                    width: 200px;
                    height: 32px;
                    background: #000000;
                    opacity: 0.5;
                    border-radius: 2px;
                    color: #ffffff;
                    font-size: 16px;
                    line-height: 36px;
                }
            }
        }
    }

    .el-input {
        width: 500px;
    }

    .el-textarea {
        width: 500px;
    }

    .goods-main {
        margin-top: 20px;
        display: flex;
        flex-wrap: wrap;

        .bottom-goods {
            margin-top: 20px;
        }
    }

    .show-goods {
        margin-top: 20px;
        display: flex;
        flex-wrap: wrap;

        .goods-item {
            width: 120px;
            position: relative;

            &:hover {
                i {
                    opacity: 1;
                }
            }

            i {
                position: absolute;
                right: -12px;
                top: -12px;
                font-size: 24px;
                cursor: pointer;
                opacity: 0;
                color: red;
            }
        }
    }

    .goods-item {
        border: 1px solid #eee;
        width: calc(20% - 20px);
        margin-right: 20px;
        display: flex;
        flex-direction: column;
        align-items: center;

        .item-cover {
            width: 100px;
            height: 100px;
            position: relative;

            img {
                max-width: 100%;
                max-height: 100%;
            }

            .item-shadow {
                height: 100%;
                width: 100%;
                background: rgba(0, 0, 0, 0.5);
                position: absolute;
                top: 0;
                display: flex;
                justify-content: center;
                align-items: center;

                i {
                    font-size: 24px;
                }
            }
        }

        &:nth-of-type(5n) {
            margin-right: 0;
        }

        .item-text {
            margin: 10px 0;
        }

        &:hover {
            cursor: pointer;
        }
    }

    ::v-deep .custom-dialog {
        min-width: 500px;
    }

    .other-item {
        position: relative;

        &::after {
            position: absolute;
            content: '*';
            color: #F56C6C;
            margin-right: 4px;
            left: 100px;
            top: 12px;
        }
    }

</style>